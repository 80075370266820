import axios from 'axios';

const instance = axios.create({
    timeout: 10000,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
});

// 请求需要带上 Client-Language 。
instance.interceptors.request.use(config => {
    var lang = window.currentLang || 'zh';
    if (lang === 'zh') {
        lang = 'zh-Hans'
    }
    config.headers['Client-Language'] = lang;

    return config;
})

export default instance;
